import { Navigate, RouteObject } from "react-router-dom";

import { NavigateWithParams } from "components/routing";
import { ErrorFallback } from "components/shared/error-fallback";
import { EModuleCode } from "features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "routes/lazy-with-retry";
import ModuleChapterRedirecter from "routes/module-chapter-redirecter";
import routeDefinitions from "routes/route-definitions";

import { settingsRoutes } from "./index._settings-routes";

const { controlRoom } = routeDefinitions;

// Parent is root /
export const controlRoomRoutes: RouteObject[] = [
  {
    path: controlRoom.module.path,
    lazy: lazyWithRetry(() => import("./control-room")),
    children: [
      {
        index: true,
        element: <ModuleChapterRedirecter moduleCode={EModuleCode.CONTROLROOM} />,
      },
      {
        path: controlRoom.settings.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.settings")),
      },
      {
        path: controlRoom.settings.details.path,
        lazy: lazyWithRetry(() => import("./control-room.settings.$ouid.$setting")),
      },
      {
        path: controlRoom.settings.create.path,
        lazy: lazyWithRetry(() => import("./control-room.settings.$ouid.new")),
      },
      {
        path: controlRoom.appSettings.overview.path,
        lazy: lazyWithRetry(
          () => import("./control-room.app-settings._index/control-room.app-settings._index"),
        ),
      },
      {
        path: controlRoom.appSettings.create.path,
        lazy: lazyWithRetry(() => import("./control-room.app-settings.new")),
      },
      {
        path: controlRoom.appSettings.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.app-settings.$ouid.$key.$app"),
            import("./control-room.app-settings.$ouid.$key.$app.delete"),
            import("./control-room.app-settings.$ouid.$key.$app.edit"),
          ]);
          return page;
        }),
        children: [
          {
            path: controlRoom.appSettings.edit.path,
            lazy: lazyWithRetry(() => import("./control-room.app-settings.$ouid.$key.$app.edit")),
          },
          {
            path: controlRoom.appSettings.delete.path,
            lazy: lazyWithRetry(() => import("./control-room.app-settings.$ouid.$key.$app.delete")),
          },
        ],
      },
      {
        path: controlRoom.monitors.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.monitors._index")),
      },
      {
        path: controlRoom.monitors.create.path,
        lazy: lazyWithRetry(() => import("./control-room.monitors.$handlerID.new")),
      },
      {
        path: controlRoom.monitors.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.monitors.$handlerID.$monitorID"),
            import("./control-room.monitors.$handlerID.$monitorID.edit"),
            import("./control-room.monitors.$handlerID.$monitorID.delete"),
            import("./control-room.monitors.$handlerID.$monitorID.conditions.new"),
            import("./control-room.monitors.$handlerID.$monitorID.conditions.$conditionID.edit"),
            import("./control-room.monitors.$handlerID.$monitorID.conditions.$conditionID.delete"),
            import("./control-room.monitors.$handlerID.$monitorID.permissions.new"),
            import("./control-room.monitors.$handlerID.$monitorID.permissions.$roleID.edit"),
            import("./control-room.monitors.$handlerID.$monitorID.permissions.$roleID.delete"),
            import("./control-room.monitors.$handlerID.$monitorID.notifications.new"),
            import(
              "./control-room.monitors.$handlerID.$monitorID.notifications.$notificationID.edit"
            ),
            import(
              "./control-room.monitors.$handlerID.$monitorID.notifications.$notificationID.delete"
            ),
          ]);

          return page;
        }),
        children: [
          {
            path: controlRoom.monitors.details.edit.path,
            lazy: lazyWithRetry(() => import("./control-room.monitors.$handlerID.$monitorID.edit")),
          },
          {
            path: controlRoom.monitors.details.delete.path,
            lazy: lazyWithRetry(
              () => import("./control-room.monitors.$handlerID.$monitorID.delete"),
            ),
          },
          {
            path: controlRoom.monitors.details.conditions.create.path,
            lazy: lazyWithRetry(
              () => import("./control-room.monitors.$handlerID.$monitorID.conditions.new"),
            ),
          },
          {
            path: controlRoom.monitors.details.conditions.edit.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./control-room.monitors.$handlerID.$monitorID.conditions.$conditionID.edit"
                ),
            ),
          },
          {
            path: controlRoom.monitors.details.conditions.delete.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./control-room.monitors.$handlerID.$monitorID.conditions.$conditionID.delete"
                ),
            ),
          },
          {
            path: controlRoom.monitors.details.permissions.create.path,
            lazy: lazyWithRetry(
              () => import("./control-room.monitors.$handlerID.$monitorID.permissions.new"),
            ),
          },
          {
            path: controlRoom.monitors.details.permissions.edit.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.monitors.$handlerID.$monitorID.permissions.$roleID.edit"),
            ),
          },
          {
            path: controlRoom.monitors.details.permissions.delete.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.monitors.$handlerID.$monitorID.permissions.$roleID.delete"),
            ),
          },
          {
            path: controlRoom.monitors.details.notifications.create.path,
            lazy: lazyWithRetry(
              () => import("./control-room.monitors.$handlerID.$monitorID.notifications.new"),
            ),
          },
          {
            path: controlRoom.monitors.details.notifications.edit.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./control-room.monitors.$handlerID.$monitorID.notifications.$notificationID.edit"
                ),
            ),
          },
          {
            path: controlRoom.monitors.details.notifications.delete.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./control-room.monitors.$handlerID.$monitorID.notifications.$notificationID.delete"
                ),
            ),
          },
        ],
      },
      {
        path: controlRoom.synchronization.wrapper.path,
        lazy: lazyWithRetry(() => import("./control-room.synchronization")),

        children: [
          {
            index: true,
            element: (
              <Navigate to={controlRoom.synchronization.synchronizationCreate.path} replace />
            ),
          },
          {
            path: controlRoom.synchronization.synchronizationCreate.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.synchronization.create/control-room.synchronization.create"),
            ),
          },
          {
            path: controlRoom.synchronization.synchronizationCompare.path,
            index: true,
            lazy: lazyWithRetry(() => import("./control-room.synchronization.compare")),
          },
        ],
      },
      {
        path: controlRoom.synchronization.synchronizationCompareDetails.path,
        lazy: lazyWithRetry(() => import("./control-room.synchronization.compare.details")),
      },
      {
        path: controlRoom.apiUsers.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.api-users")),
      },
      {
        path: controlRoom.apiUsers.create.path,
        lazy: lazyWithRetry(() => import("./control-room.api-users.new")),
      },
      {
        path: controlRoom.apiUsers.details.path,
        lazy: lazyWithRetry(() => import("./control-room.api-users.$id")),
        children: [
          {
            path: controlRoom.apiUsers.details.editUserData.path,
            lazy: lazyWithRetry(() => import("./control-room.api-users.$id.edit-user-data")),
          },
          {
            path: controlRoom.apiUsers.details.createAPIKey.path,
            lazy: lazyWithRetry(() => import("./control-room.api-users.$id.create-api-key")),
          },
        ],
      },
      {
        path: controlRoom.scripting.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.scripting._index")),
      },
      {
        path: controlRoom.scripting.details.path,
        lazy: lazyWithRetry(() => import("./control-room.scripting.$id")),
        children: [
          {
            index: true,
            element: <Navigate to="./basic-editor" replace />,
          },
          {
            path: controlRoom.scripting.details.basicEditor.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.scripting.$id.basic-editor"),
                import("./control-room.scripting.$id.basic-editor.$field-id"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.scripting.details.basicEditor.details.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.scripting.$id.basic-editor.$field-id"),
                ),
              },
            ],
          },
          {
            path: controlRoom.scripting.details.advancedEditor.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.scripting.$id.advanced-editor"),
                import("./control-room.scripting.$id.advanced-editor.edit-general-information"),
                import("./control-room.scripting.$id.advanced-editor.edit-source"),
              ]);

              return page;
            }),
            children: [
              {
                path: controlRoom.scripting.details.advancedEditor.editGeneralInformation.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./control-room.scripting.$id.advanced-editor.edit-general-information"),
                ),
              },
              {
                path: controlRoom.scripting.details.advancedEditor.editSource.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.scripting.$id.advanced-editor.edit-source"),
                ),
              },
            ],
          },
          {
            path: controlRoom.scripting.details.editableFieldConfiguration.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.scripting.$id.editable-field-configuration"),
                import("./control-room.scripting.$id.editable-field-configuration.$fieldID"),
              ]);

              return page;
            }),
            children: [
              {
                path: controlRoom.scripting.details.editableFieldConfiguration.editField.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./control-room.scripting.$id.editable-field-configuration.$fieldID"),
                ),
              },
            ],
          },
        ],
      },
      {
        path: controlRoom.scripting.new.path,
        lazy: lazyWithRetry(() => import("./control-room.scripting.new")),
      },
      {
        path: controlRoom.infrastructure.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.infrastructure")),
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./control-room.infrastructure._index")),
          },
          {
            path: controlRoom.infrastructure.createStation.path,
            lazy: lazyWithRetry(() => import("./control-room.infrastructure.new")),
          },
          {
            path: controlRoom.infrastructure.stationDetails.path,
            lazy: lazyWithRetry(() => import("./control-room.infrastructure.$id")),
            children: [
              {
                path: controlRoom.infrastructure.stationDetails.editStationDetails.path,
                lazy: lazyWithRetry(() => import("./control-room.infrastructure.$id.edit")),
              },
              {
                path: controlRoom.infrastructure.stationDetails.createDevice.path,
                lazy: lazyWithRetry(() => import("./control-room.infrastructure.$id.devices.new")),
              },
              {
                path: controlRoom.infrastructure.stationDetails.editDevice.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.infrastructure.$id.devices.$deviceId.edit"),
                ),
              },
              {
                path: controlRoom.infrastructure.stationDetails.deleteDevice.path,
                lazy: lazyWithRetry(async () => {
                  const route = await import("./control-room.infrastructure.$id");
                  return {
                    action: route.deleteDeviceAction,
                  };
                }),
              },
            ],
          },
        ],
      },
      {
        path: controlRoom.stencil.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.stencil")),
        children: [
          {
            path: controlRoom.stencil.overview.template.path,
            lazy: lazyWithRetry(() => import("./control-room.stencil.template")),
          },
          {
            path: controlRoom.stencil.overview.partial.path,
            lazy: lazyWithRetry(() => import("./control-room.stencil.partial")),
          },
          {
            path: controlRoom.stencil.overview.layout.path,
            lazy: lazyWithRetry(() => import("./control-room.stencil.layout")),
          },
        ],
      },
      {
        path: controlRoom.stencil.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.stencil.$stencilType.$id"),
            import("./control-room.stencil.$stencilType.$id.general-information"),
            import("./control-room.stencil.$stencilType.$id.editor"),
            import("./control-room.stencil.$stencilType.$id.delete"),
            import("./control-room.stencil.$stenciltype.$id.editor.send-test-email"),
            import("./control-room.stencil.$stencilType.$id.editor.thermal-preview"),
            import("./control-room.stencil.$stencilType.$id.general-information.edit"),
            import("./control-room.stencil.$stencilType.$id.general-information.edit-pdf-details"),
            import("./control-room.stencil.$stencilType.$id.editor.media-files"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams
                path={controlRoom.stencil.details.generalInformation.path}
                replace
              />
            ),
            errorElement: <ErrorFallback />,
          },
          {
            path: controlRoom.stencil.details.delete.path,
            lazy: lazyWithRetry(() => import("./control-room.stencil.$stencilType.$id.delete")),
          },
          {
            path: controlRoom.stencil.details.generalInformation.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.stencil.$stencilType.$id.general-information"),
                import("./control-room.stencil.$stencilType.$id.general-information.edit"),
                import(
                  "./control-room.stencil.$stencilType.$id.general-information.edit-pdf-details"
                ),
              ]);
              return page;
            }),

            children: [
              {
                path: controlRoom.stencil.details.generalInformation.edit.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.stencil.$stencilType.$id.general-information.edit"),
                ),
              },
              {
                path: controlRoom.stencil.details.generalInformation.editPdfDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./control-room.stencil.$stencilType.$id.general-information.edit-pdf-details"
                    ),
                ),
              },
            ],
          },
          {
            path: controlRoom.stencil.details.editor.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.stencil.$stencilType.$id.editor"),
                import("./control-room.stencil.$stencilType.$id.editor.thermal-preview"),
                import("./control-room.stencil.$stenciltype.$id.editor.send-test-email"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.stencil.details.editor.thermalPreview.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.stencil.$stencilType.$id.editor.thermal-preview"),
                ),
              },
              {
                path: controlRoom.stencil.details.editor.sendTestEmail.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.stencil.$stenciltype.$id.editor.send-test-email"),
                ),
              },
              {
                path: controlRoom.stencil.details.editor.mediaFiles.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.stencil.$stencilType.$id.editor.media-files"),
                ),
                children: [
                  {
                    path: controlRoom.stencil.details.editor.mediaFiles.deleteBlob.path,
                    lazy: lazyWithRetry(
                      () =>
                        import(
                          "./control-room.stencil.$stencilType.$id.editor.media-files.delete.$blobId"
                        ),
                    ),
                  },
                  {
                    path: controlRoom.stencil.details.editor.mediaFiles.previewBlob.path,
                    lazy: lazyWithRetry(
                      () =>
                        import(
                          "./control-room.stencil.$stencilType.$id.editor.media-files.preview.$blobId"
                        ),
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: controlRoom.stencil.new.path,
        lazy: lazyWithRetry(() => import("./control-room.stencil.$stencilType.new")),
      },
      {
        path: controlRoom.errorQueue.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.error-queue._index")),

        children: [
          {
            path: controlRoom.errorQueue.overview.republishMessages.path,
            lazy: lazyWithRetry(
              () => import("./control-room.error-queue._index.republish-messages"),
            ),
          },
        ],
      },
      {
        path: controlRoom.errorQueue.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.error-queue.$id"),
            import("./control-room.error-queue.$id.information"),
            import("./control-room.error-queue.$id.stacktrace"),
            import("./control-room.error-queue.$id.message-body"),
          ]);
          return page;
        }),
        children: [
          {
            index: true,
            element: (
              <NavigateWithParams path={controlRoom.errorQueue.details.information.path} replace />
            ),
          },
          {
            path: controlRoom.errorQueue.details.information.path,
            lazy: lazyWithRetry(() => import("./control-room.error-queue.$id.information")),
          },
          {
            path: controlRoom.errorQueue.details.stacktrace.path,
            lazy: lazyWithRetry(() => import("./control-room.error-queue.$id.stacktrace")),
          },
          {
            path: controlRoom.errorQueue.details.messageBody.path,
            lazy: lazyWithRetry(() => import("./control-room.error-queue.$id.message-body")),
          },
        ],
      },
      {
        path: controlRoom.singleSignOn.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.single-sign-on")),
        children: [
          {
            index: true,
            lazy: lazyWithRetry(() => import("./control-room.single-sign-on._index")),
          },
          {
            path: controlRoom.singleSignOn.createOpenIDProvider.path,
            lazy: lazyWithRetry(() => import("./control-room.single-sign-on.new")),
          },
          {
            path: controlRoom.singleSignOn.openIDProviderDetails.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.single-sign-on.$id"),
                import("./control-room.single-sign-on.$id.edit"),
                import("./control-room.single-sign-on.$id.claims.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.singleSignOn.openIDProviderDetails.editOpenIDProviderDetails.path,
                lazy: lazyWithRetry(() => import("./control-room.single-sign-on.$id.edit")),
              },
              {
                path: controlRoom.singleSignOn.openIDProviderDetails.editOpenIDProviderClaims.path,
                lazy: lazyWithRetry(() => import("./control-room.single-sign-on.$id.claims.edit")),
              },
            ],
          },
        ],
      },
      {
        path: controlRoom.eventExportConfiguration.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.event-export-configuration")),
      },
      {
        path: controlRoom.eventExportConfiguration.new.path,
        lazy: lazyWithRetry(() => import("./control-room.event-export-configuration.new")),
      },
      {
        path: controlRoom.eventExportConfiguration.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.event-export-configuration.$id"),
            import("./control-room.event-export-configuration.$id.delete"),
            import("./control-room.event-export-configuration.$id.edit-general-info"),
            import("./control-room.event-export-configuration.$id.edit-configuration"),
            import("./control-room.event-export-configuration.$id.add-message-type"),
            import("./control-room.event-export-configuration.$id.delete-message-type"),
            import("./control-room.event-export-configuration.$id.delete-last-message-type"),
            import("./control-room.event-export-configuration.$id.rotate-secret-key.$keyNumber"),
          ]);
          return page;
        }),
        children: [
          {
            path: controlRoom.eventExportConfiguration.details.delete.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.delete"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.editGeneralInfo.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.edit-general-info"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.editConfiguration.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.edit-configuration"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.includeOUs.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.include-ous"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.includeOUs.delete.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.event-export-configuration.$id.include-ous.$ouID.delete"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.excludeOUs.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.exclude-ous"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.excludeOUs.delete.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.event-export-configuration.$id.exclude-ous.$ouID.delete"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.addMessageType.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.add-message-type"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.deleteMessageType.path,
            lazy: lazyWithRetry(
              () => import("./control-room.event-export-configuration.$id.delete-message-type"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.deleteLastMessageType.path,
            lazy: lazyWithRetry(
              () =>
                import("./control-room.event-export-configuration.$id.delete-last-message-type"),
            ),
          },
          {
            path: controlRoom.eventExportConfiguration.details.rotateSecretKey.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./control-room.event-export-configuration.$id.rotate-secret-key.$keyNumber"
                ),
            ),
          },
        ],
      },
      {
        path: controlRoom.watchtower.overview.path,
        lazy: lazyWithRetry(async () => import("./control-room.watchtower")),
      },
      {
        path: controlRoom.watchtower.create.path,
        lazy: lazyWithRetry(() => import("./control-room.watchtower.new")),
      },

      {
        path: controlRoom.watchtower.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.watchtower.$id"),
            import("./control-room.watchtower.$id.reassign-device"),
          ]);
          return page;
        }),
        children: [
          {
            path: controlRoom.watchtower.details.reassignDevice.path,
            lazy: lazyWithRetry(() => import("./control-room.watchtower.$id.reassign-device")),
          },
        ],
      },
      {
        path: controlRoom.scheduledTasks.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks")),
      },
      {
        path: controlRoom.scheduledTasks.create.path,
        lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks.new")),
      },
      {
        path: controlRoom.scheduledTasks.executeOnce.path,
        lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks.execute-once")),
      },
      {
        path: controlRoom.scheduledTasks.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.scheduled-tasks.$id"),
            import("./control-room.scheduled-tasks.$id.edit"),
            import("./control-room.scheduled-tasks.$id.execute"),
            import("./control-room.scheduled-tasks.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: controlRoom.scheduledTasks.edit.path,
            lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks.$id.edit")),
          },
          {
            path: controlRoom.scheduledTasks.execute.path,
            lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks.$id.execute")),
          },
          {
            path: controlRoom.scheduledTasks.delete.path,
            lazy: lazyWithRetry(() => import("./control-room.scheduled-tasks.$id.delete")),
          },
        ],
      },
      {
        path: controlRoom.asyncRequests.overview.path,
        lazy: lazyWithRetry(() => import("./control-room.async-requests")),
      },
      {
        path: controlRoom.asyncRequests.details.createZonedCycleCounts.path,
        lazy: lazyWithRetry(
          () => import("./control-room.async-requests.create-zoned-cycle-counts.$id"),
        ),
      },
      {
        path: controlRoom.asyncRequests.details.createZonedCycleCountsByQuery.path,
        lazy: lazyWithRetry(
          () => import("./control-room.async-requests.create-zoned-cycle-counts-by-query.$id"),
        ),
      },
      {
        path: controlRoom.cultures.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.cultures._index"),
            import("./control-room.cultures.assortment-content-cultures"),
            import("./control-room.cultures.content-culture-mapping"),
          ]);
          return page;
        }),
        children: [
          {
            path: controlRoom.cultures.overview.assortmentContentCultures.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.cultures.assortment-content-cultures"),
                import("./control-room.cultures.assortment-content-cultures.new"),
                import(
                  "./control-room.cultures.assortment-content-cultures.$assortmentId.$languageId.$countryId.delete"
                ),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.cultures.overview.assortmentContentCultures.new.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.cultures.assortment-content-cultures.new"),
                ),
              },
              {
                path: controlRoom.cultures.overview.assortmentContentCultures.delete.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./control-room.cultures.assortment-content-cultures.$assortmentId.$languageId.$countryId.delete"
                    ),
                ),
              },
            ],
          },
          {
            path: controlRoom.cultures.overview.contentCultureMapping.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.cultures.content-culture-mapping"),
                import("./control-room.cultures.content-culture-mapping.new"),
                import("./control-room.cultures.content-culture-mapping.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.cultures.overview.contentCultureMapping.new.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.cultures.content-culture-mapping.new"),
                ),
              },
              {
                path: controlRoom.cultures.overview.contentCultureMapping.delete.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.cultures.content-culture-mapping.$id.delete"),
                ),
              },
            ],
          },
        ],
      },
      {
        path: controlRoom.entityFieldValidators.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.entity-field-validators"),
            import("./control-room.entity-field-validators.new"),
            import("./control-room.entity-field-validators.$id.delete"),
          ]);

          return page;
        }),
        children: [
          {
            path: controlRoom.entityFieldValidators.new.path,
            lazy: lazyWithRetry(() => import("./control-room.entity-field-validators.new")),
          },
          {
            path: controlRoom.entityFieldValidators.edit.path,
            lazy: lazyWithRetry(() => import("./control-room.entity-field-validators.$id")),
          },
          {
            path: controlRoom.entityFieldValidators.edit.delete.path,
            lazy: lazyWithRetry(() => import("./control-room.entity-field-validators.$id.delete")),
          },
        ],
      },

      {
        path: controlRoom.reasons.path,
        lazy: lazyWithRetry(() => import("./control-room.reasons")),

        children: [
          {
            path: controlRoom.reasons.openCashDrawer.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.open-cash-drawer"),
                import("./control-room.reasons.open-cash-drawer.create"),
                import("./control-room.reasons.open-cash-drawer.$id.edit"),
                import("./control-room.reasons.open-cash-drawer.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.openCashDrawer.createOpenCashDrawer.path,
                lazy: lazyWithRetry(() => import("./control-room.reasons.open-cash-drawer.create")),
              },
              {
                path: controlRoom.reasons.openCashDrawer.editOpenCashDrawer.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.open-cash-drawer.$id.edit"),
                ),
              },
              {
                path: controlRoom.reasons.openCashDrawer.deleteOpenCashDrawer.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.open-cash-drawer.$id.delete"),
                ),
              },
            ],
          },
          {
            path: controlRoom.reasons.taxExemption.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.tax-exemption"),
                import("./control-room.reasons.tax-exemption.create"),
                import("./control-room.reasons.tax-exemption.$id.edit"),
                import("./control-room.reasons.tax-exemption.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.taxExemption.createTaxExemption.path,
                lazy: lazyWithRetry(() => import("./control-room.reasons.tax-exemption.create")),
              },
              {
                path: controlRoom.reasons.taxExemption.editTaxExemption.path,
                lazy: lazyWithRetry(() => import("./control-room.reasons.tax-exemption.$id.edit")),
              },
              {
                path: controlRoom.reasons.taxExemption.deleteTaxExemption.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.tax-exemption.$id.delete"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.organizationReturn.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.organization-return"),
                import("./control-room.reasons.organization-return.create"),
                import("./control-room.reasons.organization-return.$id.edit"),
                import("./control-room.reasons.organization-return.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.organizationReturn.createOUReturnReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.organization-return.create"),
                ),
              },
              {
                path: controlRoom.reasons.organizationReturn.editOUReturnReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.organization-return.$id.edit"),
                ),
              },
              {
                path: controlRoom.reasons.organizationReturn.deleteOUReturnReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.organization-return.$id.delete"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.customerReturn.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.customer-return"),
                import("./control-room.reasons.customer-return.create"),
                import("./control-room.reasons.customer-return.$id.edit"),
                import("./control-room.reasons.customer-return.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.customerReturn.createCustomerReturnReason.path,
                lazy: lazyWithRetry(() => import("./control-room.reasons.customer-return.create")),
              },
              {
                path: controlRoom.reasons.customerReturn.editCustomerReturnReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.customer-return.$id.edit"),
                ),
              },
              {
                path: controlRoom.reasons.customerReturn.deleteCustomerReturnReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.customer-return.$id.delete"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.cashCorrections.overview.path,
            lazy: lazyWithRetry(() => import("./control-room.reasons.cash-corrections._index")),
            children: [
              {
                index: true,
                element: (
                  <Navigate
                    to={
                      routeDefinitions.controlRoom.reasons.cashCorrections.overview.sortedView.path
                    }
                    replace
                  />
                ),
                errorElement: <ErrorFallback />,
              },
              {
                path: controlRoom.reasons.cashCorrections.overview.sortedView.path,
                lazy: async () => {
                  const [page] = await Promise.all([
                    import("./control-room.reasons.cash-corrections.sorted-view"),
                    import("./control-room.reasons.cash-corrections.sorted-view.new"),
                    import("./control-room.reasons.cash-corrections.sorted-view.$id.edit"),
                    import("./control-room.reasons.cash-corrections.sorted-view.$id.delete"),
                  ]);
                  return page;
                },
                children: [
                  {
                    path: controlRoom.reasons.cashCorrections.overview.sortedView.new.path,
                    lazy: lazyWithRetry(
                      () => import("./control-room.reasons.cash-corrections.sorted-view.new"),
                    ),
                  },
                  {
                    path: controlRoom.reasons.cashCorrections.overview.sortedView.edit.path,
                    lazy: lazyWithRetry(
                      () => import("./control-room.reasons.cash-corrections.sorted-view.$id.edit"),
                    ),
                  },
                  {
                    path: controlRoom.reasons.cashCorrections.overview.sortedView.delete.path,
                    lazy: lazyWithRetry(
                      () =>
                        import("./control-room.reasons.cash-corrections.sorted-view.$id.delete"),
                    ),
                  },
                ],
              },
              {
                path: controlRoom.reasons.cashCorrections.overview.sortingView.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.cash-corrections.sorting-view"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.refundCorrection.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.refund-correction"),
                import("./control-room.reasons.refund-correction.create"),
                import("./control-room.reasons.refund-correction.$id.edit"),
                import("./control-room.reasons.refund-correction.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.refundCorrection.createRefundCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.refund-correction.create"),
                ),
              },
              {
                path: controlRoom.reasons.refundCorrection.editRefundCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.refund-correction.$id.edit"),
                ),
              },
              {
                path: controlRoom.reasons.refundCorrection.deleteRefundCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.refund-correction.$id.delete"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.unitPriceCorrection.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.unit-price-correction"),
                import("./control-room.reasons.unit-price-correction.create"),
                import("./control-room.reasons.unit-price-correction.$id.edit"),
                import("./control-room.reasons.unit-price-correction.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: controlRoom.reasons.unitPriceCorrection.createUnitPriceCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.unit-price-correction.create"),
                ),
              },
              {
                path: controlRoom.reasons.unitPriceCorrection.editUnitPriceCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.unit-price-correction.$id.edit"),
                ),
              },
              {
                path: controlRoom.reasons.unitPriceCorrection.deleteUnitPriceCorrectionReason.path,
                lazy: lazyWithRetry(
                  () => import("./control-room.reasons.unit-price-correction.$id.delete"),
                ),
              },
            ],
          },

          {
            path: controlRoom.reasons.taskDecline.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./control-room.reasons.task-decline"),
                import("./control-room.reasons.task-decline.new"),
                import("./control-room.reasons.task-decline.$id"),
              ]);
              return page;
            }),

            children: [
              {
                path: controlRoom.reasons.taskDecline.new.path,
                lazy: lazyWithRetry(() => import("./control-room.reasons.task-decline.new")),
              },
              {
                path: controlRoom.reasons.taskDecline.details.path,
                lazy: lazyWithRetry(async () => {
                  const [page] = await Promise.all([
                    import("./control-room.reasons.task-decline.$id"),
                    import("./control-room.reasons.task-decline.$id.delete"),
                  ]);
                  return page;
                }),

                children: [
                  {
                    path: controlRoom.reasons.taskDecline.details.delete.path,
                    lazy: lazyWithRetry(
                      () => import("./control-room.reasons.task-decline.$id.delete"),
                    ),
                  },
                ],
              },
            ],
          },
        ],
      },

      {
        path: controlRoom.customFields.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields")),
        children: [
          {
            path: controlRoom.customFields.order.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.order")),
          },

          {
            path: controlRoom.customFields.orderLine.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.order-line")),
          },

          {
            path: controlRoom.customFields.organizationUnit.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.organization-unit")),
          },

          {
            path: controlRoom.customFields.promotion.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.promotion")),
          },

          {
            path: controlRoom.customFields.user.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.user")),
          },

          {
            path: controlRoom.customFields.wishlist.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.wishlist")),
          },

          {
            path: controlRoom.customFields.shipment.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.shipment")),
          },

          {
            path: controlRoom.customFields.task.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.task")),
          },

          {
            path: controlRoom.customFields.case.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.case")),
          },

          {
            path: controlRoom.customFields.repair.path,
            lazy: lazyWithRetry(() => import("./control-room.custom-fields.repair")),
          },
        ],
      },

      {
        path: controlRoom.customFields.order.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.order.$id")),
        children: [
          {
            path: controlRoom.customFields.order.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.order.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.order.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.order.new")),
      },

      {
        path: controlRoom.customFields.orderLine.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.order-line.$id")),
        children: [
          {
            path: controlRoom.customFields.orderLine.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.order-line.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.orderLine.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.order-line.new")),
      },

      {
        path: controlRoom.customFields.organizationUnit.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.organization-unit.$id")),
        children: [
          {
            path: controlRoom.customFields.organizationUnit.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.organization-unit.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.organizationUnit.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.organization-unit.new")),
      },

      {
        path: controlRoom.customFields.promotion.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.promotion.$id")),
        children: [
          {
            path: controlRoom.customFields.promotion.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.promotion.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.promotion.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.promotion.new")),
      },

      {
        path: controlRoom.customFields.user.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.user.$id")),
        children: [
          {
            path: controlRoom.customFields.user.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.user.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.user.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.user.new")),
      },

      {
        path: controlRoom.customFields.wishlist.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.wishlist.$id")),
        children: [
          {
            path: controlRoom.customFields.wishlist.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.wishlist.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.wishlist.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.wishlist.new")),
      },

      {
        path: controlRoom.customFields.shipment.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.shipment.$id")),
        children: [
          {
            path: controlRoom.customFields.shipment.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.shipment.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.shipment.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.shipment.new")),
      },

      {
        path: controlRoom.customFields.task.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.task.$id")),
        children: [
          {
            path: controlRoom.customFields.task.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.task.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.task.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.task.new")),
      },

      {
        path: controlRoom.customFields.case.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.case.$id")),
        children: [
          {
            path: controlRoom.customFields.case.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.case.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.case.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.case.new")),
      },

      {
        path: controlRoom.customFields.repair.details.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.repair.$id")),
        children: [
          {
            path: controlRoom.customFields.repair.details.editGeneralInformation.path,
            lazy: lazyWithRetry(
              () => import("./control-room.custom-fields.repair.$id.edit-general-info"),
            ),
          },
        ],
      },

      {
        path: controlRoom.customFields.repair.new.path,
        lazy: lazyWithRetry(() => import("./control-room.custom-fields.repair.new")),
      },

      {
        path: controlRoom.addressRequirements.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.address-requirements"),
            import("./control-room.address-requirements.$countryID"),
          ]);

          return page;
        }),
      },

      {
        path: controlRoom.addressRequirements.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./control-room.address-requirements.$countryID"),
            import("./control-room.address-requirements.$countryID.$requirementID"),
          ]);

          return page;
        }),
        children: [
          {
            path: controlRoom.addressRequirements.details.editRequirement.path,
            lazy: lazyWithRetry(
              () => import("./control-room.address-requirements.$countryID.$requirementID"),
            ),
          },
        ],
      },

      /**
       * EVA Intent Handler
       */
      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },

  // Handle intents and redirects for settings module
  settingsRoutes,
];
