import { RouteObject } from "react-router-dom";

import { ErrorFallback } from "components/shared/error-fallback";
import { EModuleCode } from "features/chapter-finder/chapter-finder.types";
import { evaIntentHandlerLoader } from "routes/eva-intent-handler/eva-intent-handler";
import lazyWithRetry from "routes/lazy-with-retry";
import ModuleChapterRedirecter from "routes/module-chapter-redirecter";
import routeDefinitions from "routes/route-definitions";
import tasks from "routes/route-definitions/tasks";

import { adminSuiteConfig } from "../../../admin-suite.config";

import {
  taskDetailsCompositeRedirecterLoader,
  taskDetailsSimpleRedirecterLoader,
} from "./tasks.ship-from-store/shared/shared/task-details-redirecter.loader";

const getRepairsChapter = () => {
  let routes: RouteObject[] = [];

  routes = [
    {
      path: tasks.repairs.overview.path,
      lazy: lazyWithRetry(async () => {
        const [page] = await Promise.all([import("./tasks.repairs")]);
        return page;
      }),
    },
    {
      path: tasks.repairs.createRepair.path,
      lazy: lazyWithRetry(async () => {
        const [page] = await Promise.all([
          import("./tasks.repairs.new"),
          import("./tasks.repairs.new.search-product"),
          import("./tasks.repairs.new.search-owned-products"),
          import("./tasks.repairs.new.search-consumer-orders"),
          import("./tasks.repairs.new.search-consumer"),
          import("./tasks.repairs.new.create-consumer"),
        ]);
        return page;
      }),
      children: [
        {
          path: tasks.repairs.createRepair.addProductOrOrderLine.searchProduct.path,
          lazy: lazyWithRetry(() => import("./tasks.repairs.new.search-product")),
        },
        {
          path: tasks.repairs.createRepair.addProductOrOrderLine.searchOwnedProducts.path,
          lazy: lazyWithRetry(() => import("./tasks.repairs.new.search-owned-products")),
        },
        {
          path: tasks.repairs.createRepair.addProductOrOrderLine.searchConsumerOrders.path,
          lazy: lazyWithRetry(() => import("./tasks.repairs.new.search-consumer-orders")),
        },
        {
          path: tasks.repairs.createRepair.addConsumer.searchConsumer.path,
          lazy: lazyWithRetry(() => import("./tasks.repairs.new.search-consumer")),
        },
        {
          path: tasks.repairs.createRepair.addConsumer.createConsumer.path,
          lazy: lazyWithRetry(() => import("./tasks.repairs.new.create-consumer")),
        },
      ],
    },
    {
      path: tasks.repairs.details.path,
      lazy: lazyWithRetry(async () => {
        const [page] = await Promise.all([
          import("./tasks.repairs.$id"),
          import("./tasks.repairs.$id.repair-details"),
          import("./tasks.repairs.$id.interaction-logs"),
        ]);
        return page;
      }),
      children: [
        {
          path: tasks.repairs.details.repairDetails.path,
          lazy: lazyWithRetry(async () => {
            const [page] = await Promise.all([
              import("./tasks.repairs.$id.repair-details"),
              import("./tasks.repairs.$id.repair-details.add-product.$repairOrderId"),
              import("./tasks.repairs.$id.repair-details.add-media.$caseId"),
              import("./tasks.repairs.$id.repair-details.delete-media.path.$caseId.$blobId"),
              import("./tasks.repairs.$id.repair-details.edit-repair"),
              import("./tasks.repairs.$id.repair-details.print"),
              import("./tasks.repairs.$id.repair-details.cancel-orderline.$orderlineId"),
            ]);
            return page;
          }),
          children: [
            {
              path: tasks.repairs.details.repairDetails.addProduct.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.repair-details.add-product.$repairOrderId"),
              ),
            },
            {
              path: tasks.repairs.details.repairDetails.addMedia.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.repair-details.add-media.$caseId"),
              ),
            },
            {
              path: tasks.repairs.details.repairDetails.deleteMedia.path,
              lazy: lazyWithRetry(
                () =>
                  import("./tasks.repairs.$id.repair-details.delete-media.path.$caseId.$blobId"),
              ),
            },
            {
              path: tasks.repairs.details.repairDetails.editRepair.path,
              lazy: lazyWithRetry(() => import("./tasks.repairs.$id.repair-details.edit-repair")),
            },
            {
              path: tasks.repairs.details.repairDetails.printRepair.path,
              lazy: lazyWithRetry(() => import("./tasks.repairs.$id.repair-details.print")),
            },
            {
              path: tasks.repairs.details.repairDetails.cancelOrderline.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.repair-details.cancel-orderline.$orderlineId"),
              ),
            },
            {
              path: tasks.repairs.details.repairDetails.finishUserTask.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.repair-details.finish-user-task.$userTaskId"),
              ),
            },
            {
              path: tasks.repairs.details.repairDetails.startUserTask.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.repair-details.start-user-task.$userTaskId"),
              ),
            },
          ],
        },
        {
          path: tasks.repairs.details.interactionLogs.path,
          lazy: lazyWithRetry(async () => {
            const [page] = await Promise.all([
              import("./tasks.repairs.$id.interaction-logs"),
              import("./tasks.repairs.$id.interaction-logs.create"),
              import("./tasks.repairs.$id.interaction-logs.edit.$interactionLogId"),
              import("./tasks.repairs.$id.interaction-logs.delete.$interactionLogId"),
            ]);
            return page;
          }),
          children: [
            {
              path: tasks.repairs.details.interactionLogs.create.path,
              lazy: lazyWithRetry(() => import("./tasks.repairs.$id.interaction-logs.create")),
            },
            {
              path: tasks.repairs.details.interactionLogs.edit.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.interaction-logs.edit.$interactionLogId"),
              ),
            },
            {
              path: tasks.repairs.details.interactionLogs.delete.path,
              lazy: lazyWithRetry(
                () => import("./tasks.repairs.$id.interaction-logs.delete.$interactionLogId"),
              ),
            },
          ],
        },
      ],
    },
  ];

  return routes;
};

const getDeliverTasksRoutes = () => {
  let deliverTaskAssigneeWarningRoutes: RouteObject[] = [];
  let deliverTasksOverviewRoutes: RouteObject[] = [];
  let deliverTasksDetailsRoutes: RouteObject[] = [];

  if (adminSuiteConfig.deliverTasks) {
    deliverTaskAssigneeWarningRoutes = [
      {
        path: tasks.shipFromStore.taskAssigneeWarning.deliver.simpleTask.path,
        lazy: lazyWithRetry(
          () => import("./tasks.ship-from-store.deliver.$id.task-assignee-warning"),
        ),
      },
      {
        path: tasks.shipFromStore.taskAssigneeWarning.deliver.compositeTask.path,
        lazy: lazyWithRetry(
          () => import("./tasks.ship-from-store.deliver.$compositeId.$id.task-assignee-warning"),
        ),
      },
    ];

    deliverTasksOverviewRoutes = [
      {
        path: tasks.shipFromStore.deliver.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.ship-from-store.deliver")),
      },
    ];

    deliverTasksDetailsRoutes = [
      {
        path: tasks.shipFromStore.deliver.details.simpleTask.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.ship-from-store.deliver.$id"),
            import("./tasks.ship-from-store.deliver.$id.edit-contact-details"),
            import("./tasks.ship-from-store.deliver.$id.edit-shipping-address"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.shipFromStore.deliver.details.simpleTask.editContactDetails.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.deliver.$id.edit-contact-details"),
            ),
          },
          {
            path: routeDefinitions.tasks.shipFromStore.deliver.details.simpleTask
              .editShippingAddress.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.deliver.$id.edit-shipping-address"),
            ),
          },
        ],
      },
      {
        path: tasks.shipFromStore.deliver.details.compositeTask.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.ship-from-store.deliver.$compositeId.$id"),
            import("./tasks.ship-from-store.deliver.$compositeId.$id.edit-contact-details"),
            import("./tasks.ship-from-store.deliver.$compositeTaskId.$id.edit-shipping-address"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.shipFromStore.deliver.details.compositeTask.editContactDetails.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.deliver.$compositeId.$id.edit-contact-details"),
            ),
          },
          {
            path: routeDefinitions.tasks.shipFromStore.deliver.details.compositeTask
              .editShippingAddress.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./tasks.ship-from-store.deliver.$compositeTaskId.$id.edit-shipping-address"
                ),
            ),
          },
        ],
      },
    ];
  }

  return {
    deliverTaskAssigneeWarningRoutes,
    deliverTasksOverviewRoutes,
    deliverTasksDetailsRoutes,
  };
};

const getPackTasksRoutes = () => {
  let packTaskAssigneeWarningRoutes: RouteObject[] = [];
  let packTasksOverviewRoutes: RouteObject[] = [];
  let packTasksDetailsRoutes: RouteObject[] = [];

  if (adminSuiteConfig.packTasks) {
    packTaskAssigneeWarningRoutes = [
      {
        path: tasks.shipFromStore.taskAssigneeWarning.pack.simpleTask.path,
        lazy: lazyWithRetry(() => import("./tasks.ship-from-store.pack.$id.task-assignee-warning")),
      },
      {
        path: tasks.shipFromStore.taskAssigneeWarning.pack.compositeTask.path,
        lazy: lazyWithRetry(
          () => import("./tasks.ship-from-store.pack.$compositeId.$id.task-assignee-warning"),
        ),
      },
    ];

    packTasksOverviewRoutes = [
      {
        path: tasks.shipFromStore.pack.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.ship-from-store.pack")),
      },
    ];

    packTasksDetailsRoutes = [
      {
        path: tasks.shipFromStore.pack.details.simpleTask.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.ship-from-store.pack.$id"),
            import("./tasks.ship-from-store.pack.$id.edit-contact-details"),
            import("./tasks.ship-from-store.pack.$id.edit-shipping-address"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.shipFromStore.pack.details.simpleTask.editContactDetails.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.pack.$id.edit-contact-details"),
            ),
          },
          {
            path: routeDefinitions.tasks.shipFromStore.pack.details.simpleTask.editShippingAddress
              .path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.pack.$id.edit-shipping-address"),
            ),
          },
        ],
      },
      {
        path: tasks.shipFromStore.pack.details.compositeTask.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.ship-from-store.pack.$compositeId.$id"),
            import("./tasks.ship-from-store.pack.$compositeId.$id.edit-contact-details"),
            import("./tasks.ship-from-store.pack.$compositeTaskId.$id.edit-shipping-address"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.shipFromStore.pack.details.compositeTask.editContactDetails.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.pack.$compositeId.$id.edit-contact-details"),
            ),
          },
          {
            path: routeDefinitions.tasks.shipFromStore.pack.details.compositeTask
              .editShippingAddress.path,
            lazy: lazyWithRetry(
              () =>
                import("./tasks.ship-from-store.pack.$compositeTaskId.$id.edit-shipping-address"),
            ),
          },
        ],
      },
    ];
  }

  return {
    packTaskAssigneeWarningRoutes,
    packTasksOverviewRoutes,
    packTasksDetailsRoutes,
  };
};
// Parent is root /
export const tasksRoutes: RouteObject[] = [
  {
    path: tasks.module.path,
    lazy: lazyWithRetry(() => import("./tasks")),
    children: [
      { index: true, element: <ModuleChapterRedirecter moduleCode={EModuleCode.TASKS} /> },

      {
        path: tasks.shipFromStore.overview.path,
        children: [
          {
            path: tasks.shipFromStore.taskAssigneeWarning.pick.simpleTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.pick.$id.task-assignee-warning"),
            ),
          },
          {
            path: tasks.shipFromStore.taskAssigneeWarning.pick.compositeTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.pick.$compositeId.$id.task-assignee-warning"),
            ),
          },
          {
            path: tasks.shipFromStore.taskAssigneeWarning.ship.simpleTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.ship.$id.task-assignee-warning"),
            ),
          },
          {
            path: tasks.shipFromStore.taskAssigneeWarning.ship.compositeTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.ship.$compositeId.$id.task-assignee-warning"),
            ),
          },

          {
            path: tasks.shipFromStore.taskAssigneeWarning.print.simpleTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.print.$id.task-assignee-warning"),
            ),
          },
          {
            path: tasks.shipFromStore.taskAssigneeWarning.print.compositeTask.path,
            lazy: lazyWithRetry(
              () => import("./tasks.ship-from-store.print.$compositeId.$id.task-assignee-warning"),
            ),
          },
          ...getDeliverTasksRoutes().deliverTaskAssigneeWarningRoutes,
          ...getPackTasksRoutes().packTaskAssigneeWarningRoutes,
          {
            path: tasks.shipFromStore.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store"),
                import("./tasks.ship-from-store.pick"),
                import("./tasks.ship-from-store.ship"),
                import("./tasks.ship-from-store.print"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.pick.overview.path,
                lazy: lazyWithRetry(() => import("./tasks.ship-from-store.pick")),
              },
              {
                path: tasks.shipFromStore.ship.overview.path,
                lazy: lazyWithRetry(() => import("./tasks.ship-from-store.ship")),
              },
              {
                path: tasks.shipFromStore.print.overview.path,
                lazy: lazyWithRetry(() => import("./tasks.ship-from-store.print")),
              },
              ...getDeliverTasksRoutes().deliverTasksOverviewRoutes,
              ...getPackTasksRoutes().packTasksOverviewRoutes,
            ],
          },
          {
            path: tasks.shipFromStore.taskDetailsRedirecter.simpleTask.path,
            loader: taskDetailsSimpleRedirecterLoader,
            errorElement: <ErrorFallback />,
          },
          {
            path: tasks.shipFromStore.taskDetailsRedirecter.compositeTask.path,
            loader: taskDetailsCompositeRedirecterLoader,
            errorElement: <ErrorFallback />,
          },
          {
            path: tasks.shipFromStore.pick.details.simpleTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.pick.$id"),
                import("./tasks.ship-from-store.pick.$id.edit-contact-details"),
                import("./tasks.ship-from-store.pick.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.pick.details.simpleTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.pick.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.pick.details.simpleTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.pick.$id.edit-shipping-address"),
                ),
              },
            ],
          },
          {
            path: tasks.shipFromStore.pick.details.compositeTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.pick.$compositeId.$id"),
                import("./tasks.ship-from-store.pick.$compositeId.$id.edit-contact-details"),
                import("./tasks.ship-from-store.pick.$compositeTaskId.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.pick.details.compositeTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./tasks.ship-from-store.pick.$compositeId.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.pick.details.compositeTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./tasks.ship-from-store.pick.$compositeTaskId.$id.edit-shipping-address"
                    ),
                ),
              },
            ],
          },
          {
            path: tasks.shipFromStore.ship.details.simpleTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.ship.$id"),
                import("./tasks.ship-from-store.ship.$id.edit-contact-details"),
                import("./tasks.ship-from-store.ship.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.ship.details.simpleTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.ship.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.ship.details.simpleTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.ship.$id.edit-shipping-address"),
                ),
              },
            ],
          },
          {
            path: tasks.shipFromStore.ship.details.compositeTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.ship.$compositeId.$id"),
                import("./tasks.ship-from-store.ship.$compositeId.$id.edit-contact-details"),
                import("./tasks.ship-from-store.ship.$compositeTaskId.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.ship.details.compositeTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./tasks.ship-from-store.ship.$compositeId.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.ship.details.compositeTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./tasks.ship-from-store.ship.$compositeTaskId.$id.edit-shipping-address"
                    ),
                ),
              },
            ],
          },
          ...getDeliverTasksRoutes().deliverTasksDetailsRoutes,
          ...getPackTasksRoutes().packTasksDetailsRoutes,
          {
            path: tasks.shipFromStore.print.details.simpleTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.print.$id"),
                import("./tasks.ship-from-store.print.$id.edit-contact-details"),
                import("./tasks.ship-from-store.print.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.print.details.simpleTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.print.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.print.details.simpleTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.ship-from-store.print.$id.edit-shipping-address"),
                ),
              },
            ],
          },
          {
            path: tasks.shipFromStore.print.details.compositeTask.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.ship-from-store.print.$compositeId.$id"),
                import("./tasks.ship-from-store.print.$compositeId.$id.edit-contact-details"),
                import("./tasks.ship-from-store.print.$compositeTaskId.$id.edit-shipping-address"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.shipFromStore.print.details.compositeTask.editContactDetails.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./tasks.ship-from-store.print.$compositeId.$id.edit-contact-details"),
                ),
              },
              {
                path: routeDefinitions.tasks.shipFromStore.print.details.compositeTask
                  .editShippingAddress.path,
                lazy: lazyWithRetry(
                  () =>
                    import(
                      "./tasks.ship-from-store.print.$compositeTaskId.$id.edit-shipping-address"
                    ),
                ),
              },
            ],
          },
        ],
      },
      {
        path: tasks.incomingShipment.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.incoming-shipment")),
      },
      {
        path: tasks.incomingShipment.details.path,
        lazy: lazyWithRetry(() => import("./tasks.incoming-shipment.$id")),
      },
      {
        path: tasks.fullStockCount.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.full-stock-counts")),
      },
      {
        path: tasks.fullStockCount.details.path,
        lazy: lazyWithRetry(() => import("./tasks.full-stock-counts.$id")),
      },
      {
        path: tasks.incomingShipment.details.taskAssigneeWarning.path,
        lazy: lazyWithRetry(() => import("./tasks.incoming-shipment.$id.task-assignee-warning")),
      },
      {
        path: tasks.reservationCleanup.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.reservation-cleanup")),
      },
      {
        path: tasks.reservationCleanup.details.path,
        lazy: lazyWithRetry(() => import("./tasks.reservation-cleanup.$id")),
      },
      ...getRepairsChapter(),
      {
        path: tasks.reservation.overview.path,
        lazy: lazyWithRetry(() => import("./tasks.reservations")),
      },
      {
        path: tasks.reservation.details.path,
        lazy: lazyWithRetry(() => import("./tasks.reservations.$id")),
      },
      {
        path: tasks.print.overview.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.print"),
            import("./tasks.print.complete-tasks"),
            import("./tasks.print.complete-singular-task"),
          ]);

          return page;
        }),
        children: [
          {
            path: tasks.print.overview.completeTasks.path,
            lazy: lazyWithRetry(() => import("./tasks.print.complete-tasks")),
          },
          {
            path: tasks.print.overview.completeSingularTask.path,
            lazy: lazyWithRetry(() => import("./tasks.print.complete-singular-task")),
          },
        ],
      },
      {
        path: tasks.zonesAndZoneGroups.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.zones-and-zone-groups"),
            import("./tasks.zones-and-zone-groups.zones"),
            import("./tasks.cycle-count-zone-groups"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.zonesAndZoneGroups.zones.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.zones-and-zone-groups.zones"),
                import("./tasks.zones-and-zone-groups.zones.new"),
                import("./tasks.zones-and-zone-groups.zones.$id.edit"),
                import("./tasks.zones-and-zone-groups.zones.$id.delete"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.zonesAndZoneGroups.zones.overview.create.path,
                lazy: lazyWithRetry(() => import("./tasks.zones-and-zone-groups.zones.new")),
              },
              {
                path: tasks.zonesAndZoneGroups.zones.overview.edit.path,
                lazy: lazyWithRetry(() => import("./tasks.zones-and-zone-groups.zones.$id.edit")),
              },
              {
                path: tasks.zonesAndZoneGroups.zones.overview.delete.path,
                lazy: lazyWithRetry(() => import("./tasks.zones-and-zone-groups.zones.$id.delete")),
              },
            ],
          },
          {
            path: tasks.zonesAndZoneGroups.zoneGroups.overview.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.cycle-count-zone-groups"),
                import("./tasks.zones-and-zone-groups.zone-groups.new"),
                import("./tasks.zones-and-zone-groups.zone-groups.$id.edit"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.zonesAndZoneGroups.zoneGroups.create.path,
                lazy: lazyWithRetry(() => import("./tasks.zones-and-zone-groups.zone-groups.new")),
              },
              {
                path: tasks.zonesAndZoneGroups.zoneGroups.edit.path,
                lazy: lazyWithRetry(
                  () => import("./tasks.zones-and-zone-groups.zone-groups.$id.edit"),
                ),
              },
            ],
          },
        ],
      },

      {
        path: tasks.zonedCycleCountsAndSchedules.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.zoned-cycle-counts-and-schedules.__index"),
            import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.__index"),
            import("./tasks.zoned-cycle-counts-and-schedules.schedules"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.path,
            lazy: lazyWithRetry(async () => {
              const [page] = await Promise.all([
                import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.__index"),
                import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.upload-excel"),
              ]);
              return page;
            }),
            children: [
              {
                path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.uploadExcel.path,
                lazy: lazyWithRetry(
                  () =>
                    import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.upload-excel"),
                ),
              },
            ],
          },
          {
            path: tasks.zonedCycleCountsAndSchedules.zonedCycleCountsSchedules.path,
            lazy: lazyWithRetry(() => import("./tasks.zoned-cycle-counts-and-schedules.schedules")),
          },
        ],
      },

      {
        path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.details.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.$id"),
            import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.$id.delete"),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.details.delete.path,
            lazy: lazyWithRetry(
              () => import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.$id.delete"),
            ),
          },
        ],
      },
      {
        path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.create.path,
        lazy: lazyWithRetry(async () => {
          const [page] = await Promise.all([
            import("./tasks.zoned-cycle-counts-and-schedules.cycle-counts.new.__index"),
            import(
              "./tasks.zoned-cycle-counts-and-schedules.cycle-counts.new.ou-set.select-async-email"
            ),
            import(
              "./tasks.zoned-cycle-counts-and-schedules.cycle-counts.new.single-ou.select-async-email"
            ),
          ]);
          return page;
        }),
        children: [
          {
            path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.create.selectAsyncEmail.ouSet
              .path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./tasks.zoned-cycle-counts-and-schedules.cycle-counts.new.ou-set.select-async-email"
                ),
            ),
          },
          {
            path: tasks.zonedCycleCountsAndSchedules.zonedCycleCounts.create.selectAsyncEmail
              .singleOU.path,
            lazy: lazyWithRetry(
              () =>
                import(
                  "./tasks.zoned-cycle-counts-and-schedules.cycle-counts.new.single-ou.select-async-email"
                ),
            ),
          },
        ],
      },
      {
        path: tasks.zonedCycleCountsAndSchedules.zonedCycleCountsSchedules.details.path,
        lazy: lazyWithRetry(() => import("./tasks.zoned-cycle-counts-and-schedules.schedules.$id")),
      },

      {
        path: "link",
        loader: evaIntentHandlerLoader,
      },
    ],
  },
];
